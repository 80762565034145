<template>

<app-page theme="white" back-button="Recommendation" :no-padding="true">

	<app-title :text="content.title" :is-dark="true" :is-uppercase="true" is-restricted="true" />

	<app-content class="about-intro">

		<h2>{{ content.intro.title }}</h2>
		
		<div v-html="content.intro.text" />

	</app-content>

	<app-content class="about-grey">

		<h2>{{ content.options.title }}</h2>
		
		<app-expand v-for="(item, index) in content.options.items" :key="index" :title="item.title" :text="item.text" :is-active="option === index" v-on:click="onOptionClick(index)">

			<div class="about-item-videos">
				
				<app-button class="about-item-videos-item" v-on:click="onVideoClick(product)" v-for="(product, index) in products(item.type)" :key="index" :text="product.name" />

			</div>
			
		</app-expand>

		<div v-html="content.foot.text" class="about-foot" />

		<com-foot />

		<app-button text="Complete" theme="dark" v-on:click="onCompleteClick" class="about-button" />

	</app-content>

</app-page>

</template>

<script>

export default {

	components: {
		'com-foot': () => import('./recommendation/Foot')
	},

	data: function() {

		return {
			option: 0
		}

	},

	computed: {

		content: function() {

			return this.$store.getters['data'].info.about

		}

	},

	methods: {

		onVideoClick: function(product) {

			this.$router.push({
				name: 'Recommendation.Video',
				params: {
					product: product,
					type: this.$_.findWhere(this.$store.getters['types'], {
						id: product.type
					}),
					return: 'Recommendation.About'
				}
			})

		},

		products: function(type) {

			return this.$_.where(this.$store.getters['data'].inhalers.products, {
				type: parseInt(type)
			})

		},

		onOptionClick: function(value) {

			this.option = (this.option === value) ? false : value

		},

		onCompleteClick: function() {

			this.$store.commit('completed', 'about')

			this.$router.push({
				name: 'Recommendation'
			})

		}

	}

}

</script>

<style scoped>

.about-intro {
	background-image: url(~@/assets/recommendation.about.png?2);
	background-repeat: no-repeat;
	background-position: calc(50% + 350px) 0px;
	min-height: 291px;
	background-size: 291px 272px;
}

.is-mobile .about-intro {
	min-height: auto;
	padding-bottom: 280px;
	background-position: 50% 100%;
}

.about-intro >>> .content-inner {
	padding-right: 400px;
}

.is-mobile .about-intro >>> .content-inner {
	padding: 0px 10px;
}

.about-grey {
	background-color: #F0F0F0;
	padding: 0px 10px;
}

.about-item-videos {
	display: flex;
	margin-top: 30px;
	justify-content: center;
	flex-wrap: wrap;
}

.about-item-videos-item {
	width: auto;
	margin: 10px;
}

.is-mobile .about-item-videos-item {
	width: 100%;
	margin: 0px 0px 10px 0px;
}

.about-button {
	margin-top: 80px;
}

.is-mobile .about-button {
	margin-top: 40px;
}

.about-foot {
	margin-top: 40px;
}

.is-mobile .about-foot {
	margin-top: 20px;
}

</style>
